import React, { createContext, useReducer, useEffect, useState } from "react";
import Ente from "./components/Ente";
import Contribuyente from "./components/Contribuyente";
import Items from "./components/Items";
import Header from "./components/Header";
import { Container, Card } from "react-bootstrap";
import wrapperFilaBoleta from "./components/WrapperFilaBoleta";
import Resumen from "./components/Resumen";
import { boletaReducer, initialState } from "./reducers/boletaReducer";
import "./App.css";
import { useLocation } from "react-router-dom";

export const BoletaContext = createContext();

const BoletaContextProvider = (props) => {
  const [state, dispatch] = useReducer(boletaReducer, initialState);
  return (
    <BoletaContext.Provider value={{ state, dispatch }}>
      {props.children}
    </BoletaContext.Provider>
  );
};

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default function App() {
  const query = useQuery();
  const param_visibilidad = query.get("visibilidad");
  const [visibilidad, setVisibilidad] = useState("publico");
  useEffect(() => {
    param_visibilidad && setVisibilidad(param_visibilidad);
  }, [param_visibilidad]);

  return (
    <BoletaContextProvider>
      <Container className="max-width=560 min-vh-80">
        <Card>
          <Card.Header id="header">
            <Header />
          </Card.Header>
          <Card.Body>
            {wrapperFilaBoleta(Ente)}
            {wrapperFilaBoleta(Items, visibilidad)}
            {wrapperFilaBoleta(Contribuyente)}
            {wrapperFilaBoleta(Resumen)}
          </Card.Body>
        </Card>
        <Card.Footer className="bg-primary text-white">
          Agencia de Recaudacion Fueguina - 2020
        </Card.Footer>
      </Container>
    </BoletaContextProvider>
  );
}
