import axios from "axios";
import { formatFecha } from "../components/helpers/fechas";

const urlBase = process.env.REACT_APP_BASE_DRACMA_URL;
const urlDracmaBase = process.env.REACT_APP_BASE_DRACMA_URL;

export const urlEntes = (ente) => `${urlBase}/tipos_pago/organismos?codigo=${ente}`;

const tokenVencido = (fechaToken, token) => {
  return !fechaToken || !token || fechaToken < formatFecha(Date());
};

let entes = [];

async function getToken() {
  let token = sessionStorage.getItem("token_id");
  let fechaToken = sessionStorage.getItem("d_token");

  if (!fechaToken || !token || tokenVencido(fechaToken, token)) {
    const response = await axios.get(
      `${process.env.REACT_APP_URL_JWT}?username=${process.env.REACT_APP_USER_JWT}&password=${process.env.REACT_APP_PASS_JWT}`
    );
    token = response.data.access_token;
    sessionStorage.setItem("token_id", token);
    sessionStorage.setItem("d_token", formatFecha(Date()));
  }
  return token;
}

export async function getEntes() {
  try {
    const response = await axios.get(`${urlBase}/tipos_pago/organismos`, {
      headers: {
        Authorization: "Bearer " + (await getToken()),
        "Content-Type": "application/json",
      },
    });
    if (response?.status === 200) {
      entes = response.data;
    }
    return response;
  } catch (error) {
    return { status: 400 };
  }
}

export async function getItemsEnte(ente) {
  try {
    if (ente) {
      if (entes?.length >= 0) {
        const enteSel = entes.filter((e) => Number(e.codigo) === Number(ente));
        return { status: 200, data: enteSel };
      } else {
        const response = await axios.get(urlEntes(ente), {
          headers: {
            Authorization: "Bearer " + (await getToken()),
            "Content-Type": "application/json",
          },
        });
        return response;
      }
    } else {
      return {
        status: 400,
      };
    }
  } catch (error) {
    return { status: 400 };
  }
}

export async function getValidarCuit(cuit, filtro) {
  // if (process.env.NODE_ENV === "production") {
  //   return { status: 400 };
  // } else {
  try {
    if (cuit && filtro) {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_VALIDAR}contribuyente/validar?cuit=${cuit}&filtro=${filtro}`,
        {
          headers: {
            Authorization: "Bearer " + (await getToken()),
            "Content-Type": "application/json",
          },
        }
      );
      return response;
    } else {
      return { status: 400 };
    }
  } catch {
    return { status: 400 };
    // }
  }
}

export async function getCuotasPPG(cuit, nroPlan) {
  try {
    if (cuit && nroPlan) {
      const response = await axios.get(`${process.env.REACT_APP_URL_PPG}/obtener_cuotas?cuit=${cuit}&nro=${nroPlan}`, {
        headers: {
          Authorization: "Basic " + btoa(`${process.env.REACT_APP_USER_JWT}:${process.env.REACT_APP_PASS_JWT}`),
          "Content-Type": "application/json",
        },
      });
      return response;
    } else {
      return { status: 400 };
    }
  } catch {
    return { status: 400 };
  }
}

export async function getInteres(json) {
  try {
    if (json) {
      const response = await axios.post(
        `${urlDracmaBase}/calcular_recargo`,
        //`https://dracma.aref.gob.ar/dracma-api/v1/calcular_recargo`,
        // `http://ws-homo.aref.gob.ar/dracma-api/v1/calcular_recargo`,
        json,
        {
          headers: {
            Authorization: "Bearer " + (await getToken()),
            "Content-Type": "application/json",
          },
        }
      );
      return response;
    } else {
      return { status: 400 };
    }
  } catch {
    return { status: 400 };
  }
}

export async function generarVep(json) {
  try {
    if (json) {
      const response = await axios.post(`${urlDracmaBase}/generar_vep`, json, {
        headers: {
          Authorization: "Bearer " + (await getToken()),
          "Content-Type": "application/json",
        },
      });
      return response;
    } else {
      return {
        status: 400,
      };
    }
  } catch (error) {
    return {
      status: 400,
    };
  }
}

export async function getContribuyente(cuit) {
  try {
    if (cuit) {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_SERVICIOS}contribuyente?cuit=${cuit}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      return response;
    } else {
      return { status: 400 };
    }
  } catch {
    return { status: 400 };
  }
}
