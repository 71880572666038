import dayjs from "dayjs";
import "dayjs/locale/es"; // load on demand

dayjs.locale("es");

export const formatFecha = (fecha) => {
  return dayjs(fecha).format("DD/MM/YYYY");
};

export const fechaMayorIgualHoy = (fecha) => {
  const hoy = new Date();
  hoy.setHours(0, 0, 0, 0);
  const fechaComparar = new Date(fecha);
  fechaComparar.setHours(0, 0, 0, 0);
  return fechaComparar >= hoy;
};

export const fechaString = (fecha) => {
  if (!!fecha) {
    return (
      fecha.getFullYear() + "-" + (fecha.getMonth() + 1) + "-" + fecha.getDate()
    );
  }
  return undefined;
};
