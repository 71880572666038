import React, { useContext, useState } from "react";
import TablaItems from "./TablaItems";
import AltaItem from "./AltaItem";
import { BoletaContext } from "../App";
import {
  Button,
  Col,
  Container,
  Card,
  ButtonGroup,
  Row,
  Spinner,
} from "react-bootstrap";

import {
  maxItems,
  CHANGE_PASO,
  SET_MODAL_ALTA,
  SET_FECHA,
  PASO_ITEMS,
  PASO_CONTRIBUYENTE,
  PASO_ENTE,
} from "./helpers/constants";
import { formatFecha } from "./helpers/fechas";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from "react-datepicker";
import es from "date-fns/locale/es";
registerLocale("es", es);

export default function Items({ visibilidad }) {
  const { dispatch, state } = useContext(BoletaContext);
  const { paso, fecha, items, total } = state;

  const [loading, setLoading] = useState(false);
  const handleSiguiente = () => {
    dispatch({
      type: CHANGE_PASO,
      value: PASO_CONTRIBUYENTE,
    });
  };

  const handleAnterior = () => {
    dispatch({
      type: CHANGE_PASO,
      value: PASO_ENTE,
    });
  };

  const handleClickItem = () => {
    dispatch({
      type: SET_MODAL_ALTA,
      value: true,
    });
  };

  const handleFecha = (date) => {
    dispatch({
      type: SET_FECHA,
      value: date,
    });
  };

  if (paso === PASO_ITEMS) {
    return (
      <div>
        <Row className="m-4">
          <Col sm="4" className="text-right ">
            Fecha Boleta
          </Col>
          <Col sm="6">
            <ReactDatePicker
              value={fecha}
              selected={fecha}
              name="fecha"
              readOnly={items.length > 0}
              onChange={handleFecha}
              locale="es"
              minDate={new Date()}
              dateFormat="dd/MM/yyyy"
              className="form-control"
              autoComplete="off"
            />
          </Col>
          <Col sm="2"></Col>
        </Row>
        {fecha ? (
          <Container>
            <AltaItem setLoading={setLoading} visibilidad={visibilidad} />
            <Card>
              <Card.Header>
                Boleta de Pago
                {items.length < maxItems && !loading ? (
                  <Button
                    variant="primary"
                    className="float-right"
                    onClick={handleClickItem}
                  >
                    Nuevo
                  </Button>
                ) : (
                  <></>
                )}
              </Card.Header>
              <Card.Body className="p-0">
                {!loading ? (
                  <TablaItems />
                ) : (
                  <Spinner
                    animation="border"
                    role="status"
                    className="text-center"
                  >
                    <span className="sr-only"></span>
                  </Spinner>
                )}
              </Card.Body>
              <Card.Footer>
                <ButtonGroup>
                  <Button
                    variant="secondary"
                    as="input"
                    type="button"
                    onClick={handleAnterior}
                    value="Anterior"
                  />
                  {items.length > 0 ? (
                    <Button
                      as="input"
                      type="button"
                      onClick={handleSiguiente}
                      value="Siguiente"
                    />
                  ) : (
                    <></>
                  )}
                </ButtonGroup>
              </Card.Footer>
            </Card>
          </Container>
        ) : (
          <></>
        )}
      </div>
    );
  } else if (paso === PASO_CONTRIBUYENTE) {
    return (
      <h5 className="text-center">
        Fecha {formatFecha(fecha)} - Total: $ {total}
      </h5>
    );
  } else {
    return <></>;
  }
}
