import {
  ADD_CONTRIBUYENTE,
  ADD_ITEM,
  CHANGE_PASO,
  DELETE_ITEM,
  SET_ENTE,
  SET_FECHA,
  SET_MODAL_ALTA,
  ADD_VEP,
  PASO_ENTE,
  RESET_VEP,
  SET_VALIDA_CUIT,
  SET_CUIT_PPG,
  mostrarCantidad,
} from "../components/helpers/constants";

export const boletaReducer = (state, action) => {
  switch (action.type) {
    case ADD_CONTRIBUYENTE: {
      return {
        ...state,
        contribuyente: action.value,
      };
    }
    case ADD_ITEM: {
      const cantidad_item = mostrarCantidad ? action.value.cantidad : 1;
      const newItems = [
        ...state.items,
        { ...action.value, cantidad: cantidad_item, id: nextId++ },
      ];
      const newTotal = totalItems(newItems).toFixed(2);
      return {
        ...state,
        items: newItems,
        total: newTotal,
      };
    }
    case DELETE_ITEM: {
      const newItems = state.items.filter(
        (item) => item.id !== action.value.id
      );
      const newTotal = totalItems(newItems);
      return {
        ...state,
        items: newItems,
        total: newTotal,
      };
    }
    case CHANGE_PASO: {
      return {
        ...state,
        paso: action.value,
      };
    }

    case SET_ENTE: {
      if (
        !state.ente ||
        (action.value && action.value.codigo !== state.ente.codigo)
      ) {
        return {
          ...state,
          ente: action.value,
          items: [],
          total: 0,
        };
      } else {
        return { ...state };
      }
    }
    case SET_FECHA: {
      return {
        ...state,
        fecha: action.value,
      };
    }
    case SET_MODAL_ALTA: {
      return {
        ...state,
        modalAlta: action.value,
      };
    }

    case ADD_VEP: {
      const itemsVep = action.value.items;
      return {
        ...state,
        vep: action.value,
        items: itemsVep,
      };
    }

    case RESET_VEP: {
      const { contribuyente } = state;

      return {
        ...initialState,
        contribuyente: contribuyente,
      };
    }

    case SET_VALIDA_CUIT: {
      const filtro = action.value;
      return {
        ...state,
        valida_cuit: filtro,
      };
    }

    case SET_CUIT_PPG: {
      const filtro = action.value;
      return {
        ...state,
        cuitPpg: filtro,
      };
    }
    default:
      return state;
  }
};
let nextId = 0;

export const totalItems = (items) => {
  return items
    ? items
      .map(({ importeActualizado }) => importeActualizado)
      .reduce((sum, i) => sum + parseFloat(i), 0)
    : 0;
};



export const initialState = {
  contribuyente: {},
  ente: {},
  items: [],
  fecha: null,
  paso: PASO_ENTE,
  total: 0,
  modalAlta: false,
  vep: {},
};
