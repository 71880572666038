import React, { useContext, useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { BoletaContext } from "../App";
import { capitalizeFirstLetter, filtrarItems } from "./helpers/items";
import { Form, Button, Modal, ButtonGroup, Spinner, Alert, Col, Card, Row } from "react-bootstrap";
import NumberFormat from "react-number-format";
import { ADD_ITEM, SET_MODAL_ALTA, mostrarCantidad, SET_VALIDA_CUIT, SET_CUIT_PPG } from "./helpers/constants";
import { ErrorMessage } from "@hookform/error-message";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./AltaItem.css";
import { fechaString } from "./helpers/fechas";
import { getItemsEnte, getInteres, getCuotasPPG } from "../services";
import { format, isAfter, endOfMonth, parseISO, parse, isBefore, add } from "date-fns";

export default function AltaItem({ setLoading, visibilidad }) {
  const { dispatch, state } = useContext(BoletaContext);
  const { ente, items, modalAlta, fecha } = state;
  const [ppgCuit, setPpgCuit] = useState("");
  const [ppgNroPlan, setPpgNroPlan] = useState("");
  const [itemsEnte, setItemsEnte] = useState([]);
  const [cuotas, setCuotas] = useState([]);
  const [ppgError, setPpgError] = useState("");
  const [estado, setEstado] = useState("PREVIO");

  useEffect(() => {
    async function loadItemsEnte(codigo_ente) {
      setEstado("CARGANDO");
      const result = await getItemsEnte(codigo_ente);
      if (result && result.status === 200) {
        setEstado("LISTO");
        const itemsEnte = result.data.length > 0 && result.data[0].itemsPago;
        setItemsEnte(itemsEnte);
      } else {
        setEstado("ERROR");
      }
    }
    loadItemsEnte(ente.codigo);
  }, [ente.codigo]);

  const [itemSeleccionado, setItemSeleccionado] = useState({});

  const { register, handleSubmit, control, errors } = useForm();

  const fetchItem = async (jsonItemRecargo, item) => {
    setLoading(true);
    if (isAfter(new Date(jsonItemRecargo.fecha_original), new Date(jsonItemRecargo.fecha_calculo))) {
      dispatch({
        type: ADD_ITEM,
        value: {
          ...item,
          referencia: item.referencia || "-",
          recargo: 0,
          importeActualizado: jsonItemRecargo.importe,
          importe: jsonItemRecargo.importe,
        },
      });
    } else {
      const response = await getInteres(jsonItemRecargo);
      if (response && response.status === 200) {
        dispatch({
          type: ADD_ITEM,
          value: {
            ...item,
            referencia: item.referencia || "-",
            recargo: response.data.recargo,
            importeActualizado: response.data.importe_actualizado,
            fecha: item.fecha_cuota || item.fecha,
          },
        });
      }
    }
    setLoading(false);
  };

  const getParametros = (data, item) => {
    if (item && item.parametros) {
      return item.parametros
        .filter((param) => param.tipo !== "PPG")
        .map(function (param) {
          return { id: param.id, valor: data[param.codigo] };
        });
    } else {
      return [];
    }
  };

  const fechaAtributoCalculaRecargo = (data) => {
    const itemCalculaRecargo = itemSeleccionado.parametros.find((param) => param.calcula_recargo);
    return itemCalculaRecargo?.codigo ? parse(data[itemCalculaRecargo?.codigo], "dd/MM/yyyy", new Date()) : undefined;
  };

  const onSubmit = (data) => {
    const fechaAttrCalculoRecargo = fechaAtributoCalculaRecargo(data);
    const jsonInteres = {
      importe: Number(data.importe) * (Number(data.cantidad) || 1),
      fecha_original: fechaString(fechaAttrCalculoRecargo || data.fecha || new Date()),
      fecha_calculo: fechaString(fecha || new Date()),
      cod_impuesto: itemSeleccionado.impuesto,
      cod_concepto: itemSeleccionado.cod_concepto,
      cod_tipo_pago: itemSeleccionado.cod_tipo_pago,
    };
    const itemTemp = {
      cod_tipo_pago: data.item,
      tipo_pago: itemSeleccionado.tipo_pago,
      importe: Number(data.importe), //* (Number(data.cantidad) || 1),
      importe_unitario: data.importe,
      fecha: fechaString(data.fecha || new Date()),
      referencia: data.referencia,
      cantidad: Number(data.cantidad),
      cod_impuesto: itemSeleccionado.impuesto,
      cod_concepto: itemSeleccionado.cod_concepto,
    };
    if (data.plan_de_pago) {
      const cuotasSeleccionadas = Object.keys(data).filter((prop) => prop.startsWith("ppg-cuota-") && data[prop]);

      cuotasSeleccionadas.forEach((cuotaSel) => {
        const nroCuota = cuotaSel.replace("ppg-cuota-", "");
        const itemPPG = { ...itemTemp };
        const cuotaPlan = cuotas.find((cuota) => cuota.nro_cuota === Number(nroCuota));

        const jsonInteresPPG = {
          ...jsonInteres,
          importe: cuotaPlan.importe,
          fecha_original: cuotaPlan.vencimiento,
        };
        itemPPG["importe"] = cuotaPlan.importe;
        itemPPG["nro_plan"] = ppgNroPlan;
        itemPPG["nro_cuota"] = nroCuota;
        itemPPG["parametros"] = [];
        itemPPG["referencia"] = `PLAN ${data.plan_de_pago}/${nroCuota}`;
        itemPPG["fecha_cuota"] = cuotaPlan.vencimiento;
        itemPPG["fecha_vencimiento"] = cuotaPlan.vencimiento;

        fetchItem(jsonInteresPPG, itemPPG);
      });
      dispatch({ type: SET_CUIT_PPG, value: ppgCuit });
    } else {
      itemTemp["parametros"] = getParametros(data, itemSeleccionado);
      fetchItem(jsonInteres, itemTemp);
    }
    if (itemSeleccionado.valida_cuit) {
      dispatch({
        type: SET_VALIDA_CUIT,
        value: itemSeleccionado.valida_cuit,
      });
    }
    setItemSeleccionado({});
    setPpgError("");
    setCuotas([]);

    dispatch({
      type: SET_MODAL_ALTA,
      value: false,
    });
  };

  const handleChangeItem = (e) => {
    const itemNuevo = itemsEnte.filter((item) => item.cod_tipo_pago === Number(e.target.value))[0];
    setItemSeleccionado(itemNuevo);
  };

  const handleClose = () => {
    dispatch({
      type: SET_MODAL_ALTA,
      value: false,
    });
  };

  const handleGetCuotasPPG = async (e) => {
    const response = await getCuotasPPG(ppgCuit, ppgNroPlan);
    if (response?.status === 200 || response?.status === 201) {
      const cuotasVenc = response?.data?.cuotas.filter(
        (c) => isBefore(parseISO(c.vencimiento), endOfMonth(add(new Date(), { months: 2 }))) && c.estado === "NO_PAGADO"
      );
      setCuotas(cuotasVenc);
      setPpgError("");
    } else {
      setPpgError("Hubo un error al intentar obtener las cuotas. Controle que el nro. de Plan coincida con el CUIT");
      setCuotas([]);
    }
  };

  const getNotificacion = (parametros) => {
    if (parametros && parametros.length > 0) {
      return parametros.find((param) => param.tipo === "NOTIFICACION");
    } else {
      return null;
    }
  };

  if (estado === "CARGANDO") {
    return (
      <Spinner animation="border" role="status" className="text-center">
        <span className="sr-only"> Cargando Organismos... </span>
      </Spinner>
    );
  } else if (estado === "ERROR") {
    return (
      <Alert variant="danger">
        Hubo un error al intentar recuperar los datos.Por favor intente ingresar en unos minutos
      </Alert>
    );
  }
  return (
    <Modal size="lg" show={modalAlta} onHide={handleClose} backdrop="static" keyboard={false}>
      <Modal.Header closeButton>
        <Modal.Title> Alta Item </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Form.Group as={Row} controlId="item">
            <Form.Label size="sm" column sm="3">
              Item
            </Form.Label>
            <Col sm={!errors.item ? 9 : 6}>
              <Form.Control
                size="sm"
                as="select"
                name="item"
                ref={register({ required: true })}
                onChange={handleChangeItem}
                custom
                isInvalid={!!errors.item}>
                <option value=""> Seleccione Item </option>
                {filtrarItems(items[0], itemsEnte, visibilidad).map((item) => (
                  <option value={item.cod_tipo_pago} key={item.cod_tipo_pago}>
                    {item.tipo_pago}
                  </option>
                ))}
              </Form.Control>
            </Col>
            <Col>
              <ErrorMessage
                className="text-danger"
                name="item"
                as="small"
                errors={errors}
                message="Debe seleccionar el Item"
              />
            </Col>
          </Form.Group>
          {itemSeleccionado && (!("con_importe" in itemSeleccionado) || itemSeleccionado.con_importe) ? (
            <>
              <Form.Group as={Row}>
                <Form.Label size="sm" column sm="3">
                  Importe
                </Form.Label>
                <Col sm={!errors.importe ? 9 : 6}>
                  {!itemSeleccionado.importe ? (
                    <Controller
                      name="importe"
                      rules={{ required: "Debe ingresar el importe." }}
                      as={ImporteFormat}
                      control={control}
                      className={
                        !!errors.importe
                          ? "form form-control form-control-sm text-right is-invalid"
                          : "form form-control form-control-sm text-right "
                      }
                      autoComplete="off"
                      value={itemSeleccionado.importe}
                      defaultValue={""}
                    />
                  ) : (
                    <Form.Control
                      name="importe"
                      className="text-right"
                      readOnly
                      value={itemSeleccionado.importe}
                      ref={register()}
                    />
                  )}
                </Col>
                <Col>
                  <ErrorMessage
                    className="text-danger"
                    name="importe"
                    as="small"
                    errors={errors}
                    message="Debe ingresar el importe."
                  />
                </Col>
              </Form.Group>
            </>
          ) : (
            <> </>
          )}
          {itemSeleccionado && itemSeleccionado.con_referencia ? (
            <>
              <Form.Group as={Row} controlId="referencia">
                <Form.Label size="sm" column sm="4">
                  {itemSeleccionado.leyenda_ref ? itemSeleccionado.leyenda_ref : "Referencia"}
                </Form.Label>
                <Col sm="8">
                  <Form.Control
                    name="referencia"
                    isInvalid={!!errors.referencia}
                    autoComplete="off"
                    ref={register({
                      required: "Debe ingresar la referencia.",
                      validate: (value) =>
                        (value && /^[A-Za-z0-9/-]+$/.test(value)) ||
                        "Debe contener solo caracteres, dígitos y - (guión medio)",
                    })}
                  />
                </Col>
              </Form.Group>
              <ErrorMessage className="text-danger" name="referencia" as="small" errors={errors}>
                {({ message }) => <p> {message} </p>}
              </ErrorMessage>
            </>
          ) : (
            <> </>
          )}
          {mostrarCantidad && itemSeleccionado && itemSeleccionado.con_cantidad ? (
            <Form.Group as={Row} controlId="cantidad">
              <Form.Label size="sm" column sm="3">
                Cantidad
              </Form.Label>
              <Col sm={!errors.cantidad ? 9 : 6}>
                <Controller
                  name="cantidad"
                  rules={{
                    required: "Debe ingresar la cantidad.",
                    min: {
                      value: (itemSeleccionado?.validaciones?.campo === "cantidad" && itemSeleccionado?.validaciones?.validar?.find(e => e.minimo)?.minimo) || 0,
                      message: "Debe ingresar un número mayor o igual que " + itemSeleccionado?.validaciones?.validar?.find(e => e.minimo)?.minimo || 0
                    },
                    max: {
                      value: ((itemSeleccionado?.validaciones?.campo === "cantidad" && itemSeleccionado?.validaciones?.validar?.find(e => e.maximo)?.maximo) || Number.MAX_SAFE_INTEGER),
                      message: "Debe ingresar un número menor o igual que " + itemSeleccionado?.validaciones?.validar?.find(e => e.maximo)?.maximo || Number.MAX_SAFE_INTEGER
                    }
                  }}
                  as={CantidadFormat}
                  control={control}
                  autoComplete="off"
                  defaultValue={0}
                  className={
                    !!errors.cantidad
                      ? "form form-control form-control-sm text-right is-invalid"
                      : "form form-control form-control-sm text-right "
                  }
                />
              </Col>
              <Col sm="3">
                <ErrorMessage
                  className="text-danger"
                  name="cantidad"
                  as="div"
                  errors={errors}
                  message="Debe ingresar la cantidad"
                />
              </Col>
            </Form.Group>
          ) : (
            <> </>
          )}
          {itemSeleccionado && itemSeleccionado.con_fecha ? (
            <>
              <Form.Group as={Row} controlId="fecha">
                <Form.Label size="sm" column sm="4">
                  Fecha Vencimiento
                </Form.Label>
                <br />
                <Col sm="8">
                  <Controller
                    control={control}
                    name="fecha"
                    rules={{ required: true }}
                    defaultValue={new Date()}
                    render={({ onChange, onBlur, value }) => (
                      <ReactDatePicker
                        onChange={onChange}
                        onBlur={onBlur}
                        selected={value}
                        locale="es"
                        //maxDate={new Date(fecha)}
                        dateFormat="dd/MM/yyyy"
                        className="input form form-control date-picker"
                      />
                    )}
                  />
                </Col>
              </Form.Group>
              <ErrorMessage
                className="text-danger"
                name="fecha"
                as="div"
                errors={errors}
                message="Debe ingresar la fecha."
              />
            </>
          ) : (
            <> </>
          )}
          {itemSeleccionado?.parametros?.length > 0 &&
            itemSeleccionado.parametros.map(
              (itemParam) =>
                ((itemParam.tipo === "INPUT" || itemParam.tipo === "MASCARA") && (
                  <div key={itemParam.id}>
                    <Form.Group as={Row}>
                      <Form.Label size="sm" column sm="3">
                        {capitalizeFirstLetter(itemParam.descripcion)}
                      </Form.Label>
                      <Col sm={errors[itemParam.codigo] ? 6 : 9}>
                        <Form.Control
                          style={{ textTransform: "uppercase" }}
                          size="sm"
                          id={itemParam.codigo}
                          name={itemParam.codigo}
                          isInvalid={!!errors[itemParam.codigo]}
                          autoComplete="off"
                          placeholder={itemParam?.placeholder}
                          ref={register({
                            required: itemParam.requerido && `Este campo es obligatorio`,
                            validate: (value) =>
                              itemParam.tipo === "INPUT" ||
                              (itemParam.tipo === "MASCARA" &&
                                value &&
                                new RegExp(decodeURI(itemParam.regex)).test(value)) ||
                              `${itemParam.leyenda}`,
                          })}
                        />
                      </Col>
                      <Col sm="3">
                        <ErrorMessage className="text-danger " name={itemParam.codigo} as="small" errors={errors}>
                          {({ message }) => <p> {message} </p>}
                        </ErrorMessage>
                      </Col>
                    </Form.Group>
                  </div>
                )) ||
                (itemParam.tipo === "SELECT" && (
                  <div key={itemParam.id}>
                    <Form.Group as={Row}>
                      <Form.Label size="sm" column sm="3">
                        {capitalizeFirstLetter(itemParam.descripcion)}
                      </Form.Label>
                      <Col sm={errors[itemParam.codigo] ? 6 : 9}>
                        <Form.Control
                          size="sm"
                          as="select"
                          id={itemParam.codigo}
                          name={itemParam.codigo}
                          ref={register({ required: true })}
                          //onChange={handleChangeItem}
                          custom
                          isInvalid={!!errors[itemParam.codigo]}>
                          <option value=""> Seleccione Item </option>
                          {itemParam?.lista?.map((elem) => (
                            <option value={elem.value} key={elem.value}>
                              {elem.display}
                            </option>
                          ))}
                        </Form.Control>
                        {/* <Form.Control
                                                                      size="sm"
                                                                      id={itemParam.codigo}
                                                                      name={itemParam.codigo}
                                                                      isInvalid={!!errors[itemParam.codigo]}
                                                                      autoComplete="off"
                                                                      ref={register({
                                                                        required:
                                                                          itemParam.requerido &&
                                                                          `Este campo es obligatorio`,
                                                                        validate: (value) =>
                                                                          itemParam.tipo === "INPUT" ||
                                                                          (itemParam.tipo === "MASCARA" &&
                                                                            value &&
                                                                            new RegExp(decodeURI(itemParam.regex)).test(
                                                                              value
                                                                            )) ||
                                                                          `${itemParam.leyenda}`,
                                                                      })}
                                                                    /> */}
                      </Col>
                      <Col sm="3">
                        <ErrorMessage className="text-danger " name={itemParam.codigo} as="small" errors={errors}>
                          {({ message }) => <p> {message} </p>}
                        </ErrorMessage>
                      </Col>
                    </Form.Group>
                  </div>
                )) ||
                (itemParam.tipo === "PP" && (
                  <div key={itemParam.id}>
                    <Card border="secondary">
                      <Card.Header> Plan de Pago </Card.Header>
                      <Card.Body>
                        <Form.Group>
                          <Form.Label size="sm"> CUIT Titular </Form.Label>
                          <Form.Control
                            id="ppgCuit"
                            name="ppgCuit"
                            isInvalid={!!errors["ppgCuit"]}
                            onChange={(event) => setPpgCuit(event.target.value)}
                            autoComplete="off"
                            ref={register({
                              required: `Este campo es obligatorio`,
                            })}
                          />
                        </Form.Group>
                        <Form.Group>
                          <Form.Label size="sm">{itemParam.descripcion}</Form.Label>
                          <Form.Row>
                            <Col xs={8} className="my-1">
                              <Form.Control
                                id={itemParam.codigo}
                                name={itemParam.codigo}
                                isInvalid={!!errors[itemParam.codigo]}
                                autoComplete="off"
                                onChange={(event) => {
                                  setPpgNroPlan(event.target.value);
                                }}
                                ref={register({
                                  required: itemParam.requerido && `Este campo es obligatorio`,
                                })}
                              />
                            </Col>
                            <Col xs={4} className="my-1">
                              <Button block onClick={handleGetCuotasPPG}>
                                Ver Cuotas
                              </Button>
                            </Col>
                          </Form.Row>
                        </Form.Group>
                        <ErrorMessage className="text-danger" name={itemParam.codigo} as="div" errors={errors}>
                          {({ message }) => <p> {message} </p>}
                        </ErrorMessage>
                        {cuotas && cuotas.length > 0 ? (
                          <Form.Group controlId="item">
                            <Form.Label> Cuota </Form.Label>
                            <div className="overflow-auto" style={{ maxWidth: "500px", maxHeight: "90px" }}>
                              {cuotas.map((cuota) => (
                                <Form.Check
                                  type="checkbox"
                                  ref={register}
                                  id={`ppg-cuota-${cuota.nro_cuota}`}
                                  name={`ppg-cuota-${cuota.nro_cuota}`}
                                  key={`cuota-${cuota.nro_cuota}`}
                                  label={`Cuota: ${cuota.nro_cuota} - Venc: ${format(
                                    parseISO(cuota.vencimiento),
                                    "dd/MM/RR"
                                  )} - Importe: $ ${cuota.importe}`}
                                  size="sm"
                                />
                              ))}
                            </div>
                            <ErrorMessage
                              className="text-danger"
                              name="item"
                              as="div"
                              errors={errors}
                              message="Debe seleccionar el Item"
                            />
                          </Form.Group>
                        ) : (
                          <Alert variant="info">
                            <p>
                              Debe clickear <strong> Ver Cuotas </strong> para seleccionar las cuotas del Plan.
                            </p>

                            <p className="mt-2 mb-0">Sólo se visualizará la cuota actual y cuotas impagas vencidas.</p>
                          </Alert>
                        )}
                        {ppgError && <Alert variant="danger"> {ppgError} </Alert>}
                      </Card.Body>
                    </Card>
                  </div>
                ))
            )}
          {itemSeleccionado && itemSeleccionado.con_notificacion && (
            <Alert
              variant={
                getNotificacion(itemSeleccionado.parametros) &&
                (getNotificacion(itemSeleccionado.parametros).variante || "info")
              }>
              {itemSeleccionado.parametros &&
                getNotificacion(itemSeleccionado.parametros) &&
                getNotificacion(itemSeleccionado.parametros).leyenda}
            </Alert>
          )}
          <ButtonGroup aria-label="Botones" className="float-right mt-2">
            <Button variant="secondary" onClick={handleClose}>
              Cerrar
            </Button>
            <Button as="button" type="submit" variant="primary">
              Agregar Item
            </Button>
          </ButtonGroup>
        </Form>
      </Modal.Body>
    </Modal>
  );
}

const ImporteFormat = ({ onChange, value, ...rest }) => {
  const [importe, setImporte] = React.useState();
  return (
    <NumberFormat
      {...rest}
      value={importe}
      onValueChange={(target) => {
        setImporte(target.value);
        onChange(target.value);
      }}
      isNumericString
      prefix="$ "
      decimalScale="2"
      fixedDecimalScale
    />
  );
};

const CantidadFormat = ({ onChange, value, ...rest }) => {
  const [cantidad, setCantidad] = React.useState();
  return (
    <NumberFormat
      {...rest}
      value={cantidad}
      onValueChange={(target) => {
        setCantidad(target.value);
        onChange(target.value);
      }}
      isNumericString
      decimalScale="0"
    />
  );
};
