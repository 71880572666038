export const filtrarItems = (item, items, visibilidad) => {
  const itemsActivos = items.filter(
    (elem) =>
      (typeof elem.activo === "undefined" || elem.activo) &&
      elem?.visibilidad?.find((elem_visib) => elem_visib === visibilidad)
  );
  if (!item) {
    return itemsActivos;
  }
  const itemBuscar = itemsActivos.find(
    (elem) => Number(elem.cod_tipo_pago) === Number(item.cod_tipo_pago)
  );
  if (itemBuscar) {
    return itemsActivos.filter(
      (item) =>
        itemBuscar.impuesto === item.impuesto &&
        itemBuscar.cod_concepto === item.cod_concepto
    );
  } else {
    return itemsActivos;
  }
};

export const filtrarCamposVEP = (item) => {
  return {
    importe: item.importe || item.importeActualizado,
    cantidad: item.cantidad,
    cod_tipo_pago: item.cod_tipo_pago,
    cod_impuesto: item.cod_impuesto,
    cod_concepto: item.cod_concepto,
    tipo_pago: item.tipo_pago,
    fecha: item.fecha_vencimiento || item.fecha,
    referencia: item.referencia,
    parametros: item.parametros,
    nro_plan: item.nro_plan,
    nro_cuota: item.nro_cuota,
  };
};

export function capitalizeFirstLetter(string) {
  const lowerCaseString = string.toLowerCase();
  return lowerCaseString.charAt(0).toUpperCase() + lowerCaseString.slice(1);
}
