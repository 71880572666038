import React from "react";
import { BoletaContext } from "../App";
import { Button, Card, ButtonGroup, Spinner, Table, Row, Col } from "react-bootstrap";
import { urlServer, PASO_RESUMEN, RESET_VEP, mostrarCantidad } from "./helpers/constants";
import { formatFecha } from "./helpers/fechas";
import QRCode from "qrcode.react";

export default function Resumen() {
  const { dispatch, state } = React.useContext(BoletaContext);
  const { cuitPpg, contribuyente, items, paso, vep } = state;

  const handleReset = () => {
    dispatch({
      type: RESET_VEP,
      value: {},
    });
  };

  if (paso === PASO_RESUMEN) {
    return vep && vep.url_reporte ? (
      <Card>
        <Card.Title className="text-center">
          <Row className="justify-content-between">
            <Col xs="3" className="mt-2">
              <h6>
                <strong>Contribuyente </strong> {contribuyente.razon_social}
              </h6>
              <h6>
                <strong>CUIT </strong> {contribuyente.cuit}
              </h6>
            </Col>
            <Col xs="auto" className="mt-2">
              <h4 className="mt-1">VEP # {vep.nro_vep}</h4>
              <h6>Vencimiento {formatFecha(vep.fecha_vencimiento)}</h6>
            </Col>
            <Col xs="3" className="mt-2">
              <QRCode
                value={`${process.env.REACT_APP_BASE_URL_QR}/?cuit=${cuitPpg || contribuyente.cuit}&nro_vep=${
                  vep.nro_vep
                }`}></QRCode>
            </Col>
          </Row>
        </Card.Title>
        <Card.Body>
          <div className="table-responsive">
            <Table striped borderless hover>
              <thead className="bg-200 text-900">
                <tr>
                  <th className="border-0">Item</th>
                  <th className="border-0 small">Fecha</th>
                  {mostrarCantidad && items.some((element) => !!element.cantidad) ? (
                    <th className="border-0 text-right small">Cantidad</th>
                  ) : (
                    <></>
                  )}
                  <th className="border-0 text-right small">Importe</th>
                  <th className="border-0 text-right small">Recargo</th>
                  <th className="border-0 text-right">Total</th>
                </tr>
              </thead>

              <tbody>
                {items.map((item) => (
                  <tr key={item.id || item.nro_cuota}>
                    <td className="align-middle">
                      <h6 className=" mb-0 text-nowrap"> {item.tipo_pago}</h6>
                      <p className="mb-0 text-muted">{item.referencia}</p>
                    </td>
                    <td className="small">{formatFecha(item.fecha_vencimiento || item.fecha)} </td>
                    {mostrarCantidad && !!item.cantidad ? <td className="text-right small">{item.cantidad}</td> : <></>}

                    <td className="text-right small">$ {(item.importe || 0).toFixed(2)}</td>
                    <td className="text-right small">$ {(item.recargo || 0).toFixed(2)}</td>
                    <td className="text-right">
                      {(item.importeActualizado || item.importe + item.recargo || 0).toFixed(2)}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
          <div>
            <strong>Total VEP: $ {vep.importe_actualizado.toFixed(2)}</strong>
          </div>
        </Card.Body>
        <Card.Footer className="text-center">
          <ButtonGroup className="mr-3">
            <Button variant="outline-primary" onClick={handleReset}>
              Nuevo VEP
            </Button>
          </ButtonGroup>
          <ButtonGroup>
            <Button href={urlServer + vep.url_reporte} target="_blank">
              Ver Boleta
            </Button>
            <Button
              variant="success"
              href={`${process.env.REACT_APP_URL_PAGO_BOLETA}?cuit=${cuitPpg || contribuyente.cuit}&nro_vep=${
                vep.nro_vep
              }`}
              target="_blank">
              Pagar
            </Button>
          </ButtonGroup>
        </Card.Footer>
      </Card>
    ) : (
      <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" />
    );
  } else {
    return <></>;
  }
}
