import React from "react";
import { Row, Col } from "react-bootstrap";

export default function wrapperFilaBoleta(Componente, visibilidad) {
  return (
    <Row>
      <Col>
        <Componente visibilidad={visibilidad} />
      </Col>
    </Row>
  );
}
