import React, { useState, useEffect, useContext } from "react";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { BoletaContext } from "../App";
import { Form, Button, Spinner, Alert } from "react-bootstrap";
import { PASO_ENTE, PASO_RESUMEN, PASO_ITEMS } from "./helpers/constants";
import { getEntes } from "../services";

export default function Ente() {
  const [estado, setEstado] = React.useState("PREVIO");
  const { dispatch, state } = useContext(BoletaContext);
  const { paso, ente } = state;

  const { register, handleSubmit, errors } = useForm();

  const getDescripcion = (codigo) => {
    const enteFilter = entes.filter((ente) => ente.codigo === Number(codigo));
    if (enteFilter && enteFilter.length === 1) {
      return enteFilter[0]["descripcion"];
    } else {
      return "";
    }
  };

  const onSubmit = (data) => {
    const desc_ente = getDescripcion(data.ente);
    dispatch({
      type: "SET_ENTE",
      value: {
        codigo: data.ente,
        descripcion: desc_ente,
      },
    });

    dispatch({
      type: "CHANGE_PASO",
      value: PASO_ITEMS,
    });
  };
  const [entes, setEntes] = useState([]);

  useEffect(() => {
    async function loadEntes() {
      setEstado("CARGANDO");
      const response = await getEntes();
      if (response && response.status === 200) {
        const entesActivos = response.data.filter(
          (elem) => typeof elem.activo === "undefined" || elem.activo
        );
        setEntes(entesActivos);
        setEstado("LISTO");
      } else {
        setEstado("ERROR");
      }
    }
    loadEntes();
  }, []);

  if (paso === PASO_ENTE) {
    if (estado === "CARGANDO") {
      return (
        <Spinner animation="border" role="status" className="text-center">
          <span className="sr-only">Cargando Organismos...</span>
        </Spinner>
      );
    } else if (estado === "ERROR") {
      return (
        <Alert variant="danger">
          Hubo un error al recuperar los Organismos. Intente en unos minutos
          nuevamente
        </Alert>
      );
    }

    return (
      <Form onSubmit={handleSubmit(onSubmit)} className="p-4">
        <Form.Group controlId="ente">
          <Form.Label>Ente</Form.Label>
          <Form.Control
            as="select"
            name="ente"
            ref={register({ required: true })}
            isInvalid={!!errors.ente}
            custom
            defaultValue={ente.codigo}
          >
            <option value="">Seleccione Ente</option>
            {entes.map((e) => (
              <option value={e.codigo} key={e.codigo}>
                {e.descripcion}
              </option>
            ))}
          </Form.Control>
          <ErrorMessage
            className="text-danger"
            name="ente"
            as="div"
            errors={errors}
            message="Se debe seleccionar el Organismo"
          />
        </Form.Group>
        <Button as="input" type="submit" value="Continuar" />
      </Form>
    );
  } else if (paso !== PASO_RESUMEN) {
    return (
      <h3 className="text-center text-muted">Organismo {ente.descripcion}</h3>
    );
  } else {
    return <></>;
  }
}
