import React from "react";
import { Row, Col } from "react-bootstrap";

export default function Header() {
  return (
    <Row className="text-center p-1">
      <Col sm="8">
        <h5 className="text-white">Pago en Línea</h5>
      </Col>
      <Col>
        <img
          src={process.env.PUBLIC_URL + "/assets/imgs/logo_color.png"}
          className="img-fluid"
          alt="logo AREF"
        />
      </Col>
    </Row>
  );
}
