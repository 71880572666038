import React, { useContext } from "react";
import { BoletaContext } from "../App";
import { Button, ListGroup, Container, Col, Row } from "react-bootstrap";
import { BsFillTrashFill } from "react-icons/bs";
export default function TablaItems() {
  const { dispatch, state } = useContext(BoletaContext);
  const { items, total } = state;

  const handleEliminar = (item) => {
    dispatch({
      type: "DELETE_ITEM",
      value: item,
    });
  };

  const label = (label, valor, primer) => {
    return valor ? (primer ? "" : " - ") + label + ": " + valor : "  ";
  };

  return (
    <ListGroup>
      {items.length > 0 ? (
        <ListGroup.Item>
          <Container fluid>
            <Row>
              <Col xs={7}>Item</Col>
              {/* <Col className="small">Fecha</Col> */}
              <Col className="small text-nowrap">Importe</Col>
              <Col className="small text-nowrap">Actualizado</Col>
              <Col></Col>
            </Row>
          </Container>
        </ListGroup.Item>
      ) : (
        <></>
      )}

      {items.map((item) => (
        <ListGroup.Item key={item.id}>
          <Container fluid="sm">
            <Row>
              <Col xs={7}>
                <strong>{item.tipo_pago}</strong>
                <p className="text-muted">
                  {label("Ref", item.referencia, true)}
                </p>
                {item.cantidad && (
                  <p className="text-muted">
                    {label("Cantidad", item.cantidad, true)}
                  </p>
                )}
              </Col>
              <Col className="small text-nowrap">
                ${Number(item.importe).toFixed(2)}
              </Col>
              <Col className="small text-nowrap">
                ${Number(item.importeActualizado).toFixed(2)}
              </Col>
              <Col>
                <Button
                  as="a"
                  variant="outline-danger"
                  align="center"
                  onClick={() => handleEliminar(item)}
                >
                  <BsFillTrashFill color="red" height="16" />
                </Button>
              </Col>
            </Row>
          </Container>
        </ListGroup.Item>
      ))}
      <ListGroup.Item>
        <Container fluid>
          <Row>
            <Col xs={7}>
              <strong>Total:</strong>
            </Col>
            <Col></Col>
            <Col className="text-nowrap">${Number(total).toFixed(2)}</Col>
            <Col></Col>
          </Row>
        </Container>
      </ListGroup.Item>
    </ListGroup>
  );
}
