import React, { useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { BoletaContext } from "../App";
import { Form, Button, ButtonGroup, Alert } from "react-bootstrap";
import { ErrorMessage } from "@hookform/error-message";
import NumberFormat from "react-number-format";
import {
  ADD_VEP,
  CHANGE_PASO,
  ADD_CONTRIBUYENTE,
  PASO_CONTRIBUYENTE,
  PASO_ITEMS,
  PASO_RESUMEN,
} from "./helpers/constants";
import { filtrarCamposVEP } from "./helpers/items";
import { validarCuit } from "./helpers/contribuyente";
import { generarVep, getContribuyente, getValidarCuit } from "../services";
import { useState } from "react";
// import { useEffect } from "react";

export default function Contribuyente() {
  const { register, handleSubmit, errors, control } = useForm();

  const [razonSocial, setRazonSocial] = useState("");
  const { dispatch, state } = React.useContext(BoletaContext);
  const { cuitPpg, contribuyente, paso, fecha, items } = state;
  const [estado, setEstado] = React.useState("PREVIO");
  const [mensajeError, setMensajeError] = React.useState("");

  const validarCuitCodtipoPago = (value) => {
    for (const item of state.items) {
      const cuits = (filtroCuitConcepto.find(filtro => filtro.cod_tipo_pago === item.cod_tipo_pago))?.soloCuits;

      if (cuits?.findIndex(cuit => cuit.toString() === value.toString()) !== -1) {
        return true;
      }
    };
    return false;
  }

  const fetchVEP = async (jsonVep) => {
    setEstado("CARGANDO");
    const response = await generarVep(jsonVep);
    if (response && (response.status === 200 || response.status === 201)) {
      if (response?.data?.lista_veps?.length >= 1) {
        dispatch({
          type: ADD_VEP,
          value: response.data.lista_veps[0],
        });
        setEstado("LISTO");
        dispatch({
          type: CHANGE_PASO,
          value: PASO_RESUMEN,
        });
      } else {
        setEstado("ERROR");
        setMensajeError("Hubo un error al generar la boleta, por favor intente nuevamente en unos instantes");
      }
    } else {
      setEstado("ERROR");
      setMensajeError("Hubo un error al generar la boleta, por favor intente nuevamente en unos instantes");
    }
  };

  const onSubmit = async (data) => {
    let razonSocialValida = "";
    if (state.valida_cuit) {
      const resp = await getValidarCuit(data.cuit, state.valida_cuit);
      if (resp.status !== 200) {
        setEstado("ERROR");
        setMensajeError("Hubo un error al controlar el CUIT. por favor intente nuevamente en unos minutos.");
        return;
      } else {
        if (resp?.data?.resultado) {
          razonSocialValida = resp.data.razon_social;
        } else {
          setEstado("ERROR");
          setMensajeError("La operación no está permitida para el Cuit ingresado");
          return;
        }
      }
    }
    const contribuyente = {
      ...data,
      razon_social: razonSocialValida || data.razon_social,
    };
    dispatch({
      type: ADD_CONTRIBUYENTE,
      value: contribuyente,
    });

    const jsonVep = {
      contribuyente: contribuyente || data,
      fecha_pago: fecha.toJSON(),
      items: items.map(filtrarCamposVEP),
    };
    fetchVEP(jsonVep);
  };

  const handleAnterior = () => {
    setEstado("PREVIO");
    dispatch({
      type: CHANGE_PASO,
      value: PASO_ITEMS,
    });
  };

  const setearRazonSocial = async (cuit) => {
    if (cuit && validarCuit(cuit)) {
      const resp = await getContribuyente(cuit);
      if (resp.status === 200) {
        setRazonSocial(resp.data.razon_social);
      } else {
        setRazonSocial("Hubo un error");
      }
    }

  }

  useEffect(() => {
    if (cuitPpg && validarCuit(cuitPpg)) {
      setearRazonSocial(cuitPpg);
    }
  }, [cuitPpg]);



  return paso === PASO_CONTRIBUYENTE ? (
    <Form onSubmit={handleSubmit(onSubmit)} className="p-4">
      <Form.Group>
        <Form.Label>CUIT</Form.Label>
        <Controller
          name="cuit"
          rules={{
            required: "El CUIT es requerido.",
            validate: {
              valido: (value) => validarCuit(value) || "El número de CUIT  es inválido",
              controlCuit: (value) => validarCuitCodtipoPago(value) || "El cuit no está habilitado para el item"
            },
          }}
          // as={CuitFormat}
          control={control}
          // className={!errors.cuit ? "form form-control" : "form form-control is-invalid"}
          autoComplete="off"
          defaultValue={cuitPpg || (contribuyente && contribuyente.cuit) || ""}
          render={props =>
            <CuitFormat
              isInvalid={errors.cuit}
              className="form form-control"
              value={props.value || ''}
              onChange={e => {
                if (validarCuit(e)) {
                  setearRazonSocial(e);
                }
                return props.onChange(e)
              }}

            />
          }
        />
        <ErrorMessage className="text-danger" name="cuit" as="div" errors={errors}>
          {({ message }) => <p>{message}</p>}
          {errors.cuit && errors.cuit.type === "validate" && <p>El n&uacute;mero de CUIT es inv&aacute;lido.</p>}
        </ErrorMessage>
      </Form.Group>
      {!state.valida_cuit && (
        <Form.Group>
          <Form.Label>Raz&oacute;n Social</Form.Label>
          <Form.Control
            name="razon_social"
            disabled="disabled"
            ref={register(
              // { required: "La Razon Social es requerida." }
            )}
            as="input"
            autoComplete="off"
            defaultValue={
              // (contribuyente && contribuyente.razon_social) ||
              ""}
            value={razonSocial}
            isInvalid={!!errors.razonsocial}></Form.Control>
          <ErrorMessage className="text-danger" name="razon_social" as="div" errors={errors}>
            {({ message }) => <p>{message}</p>}
          </ErrorMessage>
        </Form.Group>
      )}
      {estado === "ERROR" ? (
        <Form.Group>
          <Alert variant="danger">{mensajeError}</Alert>
        </Form.Group>
      ) : (
        <></>
      )}
      <ButtonGroup>
        <Button as="input" type="button" variant="secondary" value="Anterior" onClick={handleAnterior} />
        <Button as="input" type="submit" value="Generar VEP" />
      </ButtonGroup>
    </Form>
  ) : (
    <></>
  )
}

const CuitFormat = ({ onChange, value, ...rest }) => {
  const [cuit, setCuit] = React.useState(value);
  return (
    <NumberFormat
      {...rest}
      value={cuit}
      onValueChange={(target) => {
        setCuit(target.value);
        onChange(target.value);
      }}
      isNumericString
      format="##-########-#"
      className={!rest.isInvalid ? "form form-control" : "form form-control is-invalid"}
    />
  );
};



const filtroCuitConcepto = [{
  cod_tipo_pago: "115106038",
  soloCuits: [30707007148, 30629512124, 30675753365, 30682971580]
}]