export const maxItems = 1;
export const mostrarCantidad = true;
const urlBase = process.env.REACT_APP_BASE_URL;
const urlDracmaBase = process.env.REACT_APP_BASE_DRACMA_URL;

export const urlEntes = (ente) => `${urlBase}/tipos_pago?organismo=${ente}`;
export const urlRecargo = `${urlDracmaBase}/calcular_recargo`;
export const urlItems = "";
export const urlGenerarVep = `${urlDracmaBase}/generar_vep`;
export const urlServer = process.env.REACT_APP_DRACMA_URL_REPORT;

//Operacines Dispatch
export const ADD_ITEM = "ADD_ITEM";
export const ADD_CONTRIBUYENTE = "ADD_CONTRIBUYENTE";
export const SET_MODAL_ALTA = "SET_MODAL_ALTA";
export const CHANGE_PASO = "CHANGE_PASO";
export const DELETE_ITEM = "DELETE_ITEM";
export const SET_ENTE = "SET_ENTE";
export const SET_FECHA = "SET_FECHA";
export const ADD_VEP = "ADD_VEP";
export const RESET_VEP = "RESET_VEP";
export const SET_VALIDA_CUIT = "SET_VALIDA_CUIT";
export const SET_CUIT_PPG = "SET_CUIT_PPG";

export const PASO_ENTE = 10;
export const PASO_FECHA = 20;
export const PASO_ITEMS = 30;
export const PASO_CONTRIBUYENTE = 40;
export const PASO_RESUMEN = 50;